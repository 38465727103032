import styles from "../styles/topbar.module.scss";

const TopBar = () => {
  return (
    <div className={styles.topBar}>
      <div className={styles.leftContainer}>
        <p>
          <span className={styles.icon}>
            <img
              src="/images/location.png"
              alt="Location Icon"
              width={25}
              height={25}
            />
          </span>
          Skovdongvej 1A, 5881 Skårup Fyn
        </p>
        <p>
          <span className={styles.icon}>
            <img
              src="/images/mail.png"
              alt="Mail Icon"
              width={25}
              height={25}
            />
          </span>
          enemarkmarine@gmail.com
        </p>
        <p>
          <span className={styles.icon}>
            <img
              src="/images/phone.png"
              alt="Phone Icon"
              width={20}
              height={20}
            />
          </span>
          29 91 09 53
        </p>
      </div>
      <div className={styles.rightContainer}>
        {/*<span className={styles.icon}>
          <a href="/">
            <img
              src="/images/facebook.png"
              alt="Facebook Icon"
              width={30}
              height={30}
            />
          </a>
        </span>
        */}
        {/*<span className={styles.icon}>
          <a href="/">
            <img
              src="/images/linkedin.png"
              alt="LinkedIn Icon"
              width={24}
              height={24}
            />
          </a>
  </span>*/}
      </div>
    </div>
  );
};

export default TopBar;
