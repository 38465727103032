import styles from './styles/App.module.scss';

function App() {
  return (
    <div className={styles.app}>
      <section className={styles.info}>
        <h1>Vi tilbyder</h1>
        <ul>
          <li>Alt indenfor reperation og vedligeholdelse af både</li>
          <li>Vinteropbevaring</li>
          <li>Installation af nye og brugte motorer</li>
          <li>Bovpropel montering</li>
          <li>Gelcoat reperationer</li>
          <li>Forsikringsskader</li>
          <li>Montering af ekstraudstyr, ankerspil, oliefyr, navigation mm.</li>
        </ul>
      </section>
      <section className={styles.contact}>
        <h2>Kontakt</h2>
        <p>Telefon: <a href="tel:29910953">29 91 09 53</a></p>
        <p>Email: <a href="mailto:enemarkmarine@gmail.com">enemarkmarine@gmail.com</a></p>
        <p>Alle hverdage 7:00 - 16:00, weekender efter aftale</p>
        <p>Vagttelefonen er åben 24/7</p>
        <h4 className={styles.greeting}>Vi glæder os til at høre fra fra dig</h4>
        <h4 className={styles.name}>Martin Enemark</h4>
      </section>
    </div>
  );
}

export default App;