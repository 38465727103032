import styles from "../styles/flagbar.module.scss";

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <a href="/">
          <img src="/images/logo_white.png" alt="Your Logo" />
        </a>
      </div>
      <div className={styles.text}>
        <h3>Din bådpartner på Sydfyn</h3>
      </div>
    </div>
  );
};

export default Navbar;