import styles from "../styles/splitbar.module.scss";

const Splitbar = () => {
  return (
    <div className={styles.topBar}>
    </div>
  );
};

export default Splitbar;
